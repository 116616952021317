<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog_from_input"
      width="950"
      @click:outside="close()"
      @keydown.esc="close()"
      scrollable
    >

      <v-card
      tile
      >
        <v-card-title
          class="custom-modal-title"
          :style="backgroundColor"
          primary-title
        >
          {{edit_data ? 'Edit' : 'Create'}} User
            <v-spacer></v-spacer>
          <span @click="close" style="cursor:pointer">x</span>
        </v-card-title>

        <v-card-text>
          <v-container>
               <v-form
                    ref="form"
                    v-model="valid"
                    :lazy-validation="lazy"
                    >
                    <v-row>

                      <v-col cols="12">
                          <v-text-field
                          v-model="name"
                          label="Name"
                          :rules="nameRules"
                          required
                          ></v-text-field>
                           <v-text-field
                          v-model="email"
                          :rules="emailRules"
                          label="Email"
                          ></v-text-field>
                          <v-text-field
                          v-model="password"

                          label="Password"
                          required
                          type="password"
                          ></v-text-field>
                            <v-text-field
                          v-model="password_confirmation"
                    :rules="[passwordConfirmRules(password)]"

                          label="Password Confirmation"
                          required
                          type="password"
                          ></v-text-field>
                          <v-text-field
                          v-model="phone"
                          :rules="phoneRules"
                          label="Phone"
                          required
                          ></v-text-field>
                          <v-autocomplete
                          v-model="branch"
                          :items="branchs"
                          chips
                          label="Branch"
                          item-text="name"
                          item-value="id"
                          multiple
                          >
                            <template v-slot:selection="data">
                              <v-chip
                                v-bind="data.attrs"
                                style="color:white"
                                :input-value="data.selected"
                                close
                                @click="data.select"
                                :color="color"
                                @click:close="remove('branch')"
                              >
                                <v-avatar left>
                                  <v-img :src="img('branch',data.item.image_link)"></v-img>
                                </v-avatar>
                                {{ data.item.name }}
                              </v-chip>
                            </template>
                            <template v-slot:item="data">
                              <template v-if="typeof data.item !== 'object'">
                                <v-list-item-content v-text="data.item"></v-list-item-content>
                              </template>
                              <template v-else>
                                <v-list-item-avatar>
                                  <img :src="img('branch',data.item.image_link)">
                                </v-list-item-avatar>
                                <v-list-item-content>
                                  <v-list-item-title class="custom-list-item-title-modal" v-html="data.item.name"></v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </template>
                          </v-autocomplete>

                          <v-autocomplete
                          v-model="karyawan"
                          :items="employees"
                          chips
                          label="Employee"
                          item-text="name"
                          item-value="id"
                          >
                            <template v-slot:selection="data">
                              <v-chip
                                v-bind="data.attrs"
                                style="color:white"
                                :input-value="data.selected"
                                close
                                @click="data.select"
                                :color="color"
                                @click:close="remove('karyawan')"
                              >
                                <v-avatar left>
                                  <v-img :src="img('karyawan',data.item.image_link)"></v-img>
                                </v-avatar>
                                {{ data.item.name }}
                              </v-chip>
                            </template>
                            <template v-slot:item="data">
                              <template v-if="typeof data.item !== 'object'">
                                <v-list-item-content v-text="data.item"></v-list-item-content>
                              </template>
                              <template v-else>
                                <v-list-item-avatar>
                                  <img :src="img('karyawan',data.item.image_link)">
                                </v-list-item-avatar>
                                <v-list-item-content>
                                  <v-list-item-title class="custom-list-item-title-modal" v-html="data.item.name"></v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </template>
                          </v-autocomplete>
                          <v-textarea
                            v-model="note"
                            label="Note"
                          ></v-textarea>
                      </v-col>
                    </v-row>
               </v-form>
               <input type="file" style="display:none; " id="foto_profile" ref="foto_profile" accept="image/*" @change="eventChange">
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            tile
            class="custom-modal-title"
            @click="close"
          >
            Cancel
          </v-btn>

           <v-btn
            color="success"
            tile
            :disabled="!valid"
            @click="edit_data ? update() : save()"
            :loading="loading"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import GlobalMixin from '../../../mixin/index'
export default {
  props: ['dialog', 'edit_data'],
  mixins:[GlobalMixin],
  methods: {
    async send_data() {
      const data = new FormData()
      data.append('phone', this.phone)
      data.append('photos', this.foto)
      data.append('email', this.email)
      data.append('address', this.address)
      await this.branch.forEach(x => {
        data.append('branch[]',x)
      })
      data.append('note', this.note)
      data.append('password', this.password)
      data.append('name', this.name)
      data.append('karyawan_id', this.karyawan)
      data.append('entry_by', this.user.fullname)
      data.append('company_id', this.user.company_id)
      return data
    },
    async save () {
      this.loading = true
      const data = await this.send_data()
      await this.axios.post('v1/users/create', data)
        .then(async (ress) => {
          this.setSnakbar({
            status: true,
            pesan: 'Success submit done',
            color_snakbar: 'success'
          })
          await this.go('users','users')
          this.close()
        })
        .catch((err) => console.log(err.response))
      this.loading = false
    },

    async update() {
      this.loading = true
      const data = await this.send_data()
      await this.axios.put('v1/users/update/' + this.edit_data.id, data)
        .then(async (ress) => {
          this.setSnakbar({
            status: true,
            pesan: 'Success update data',
            color_snakbar: 'success'
          })
          await this.go('users','users')
          this.close()
        })
        .catch((err) => console.log(err.response))
      this.loading = false
    },
    close () {
      this.reset()
      this.$emit('close')
    },
    reset () {
      this.phone = ''
      this.foto = ''
      this.email = ''
      this.address = ''
      this.note = ''
      this.karyawan = ''
      this.name = ''
      this.password = ''
    this.imgurl ='img/no_img.png',

      this.branch = []
    },
    remove(data) {
      this[data] = ''
    },
    img(folder = null ,file = null) {
      if (file !== null) {
         return process.env.VUE_APP_IMGLINK + `${folder}/` + file
      } else {
        return this.imgurl
      }

    },
  },

  data: () => ({
    valid: true,
    lazy: false,
    loading: false,
    name: '',
    note: '',
    phone: '',
    password: '',
    karyawan: '',
    branch: [],
    email: '',
    address: '',
    password: '',
    password_confirmation: '',
    passwordConfirmRules(password){
            return v => v === password || 'Must be same password'
        },
    phoneRules: [
      v => !!v || 'Required field',
      (v) => !v || /^(^\+62\s?|^0)(\d{3,4}-?){2}\d{3,4}$/g.test(v) || 'Please enter a valid Phone number (ex. 0857XXXXXX)'
    ],
    emailRules: [
      v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
    ],
    nameRules: [
      v => !!v || 'Required field'
    ],
    imgurl: 'img/no_img.png',
    foto: null

  }),

  computed: {
    ...mapGetters({
      branchs: 'data/branchs',
      employees: 'data/employees'
    }),
  },
  async created () {
if (this.edit_data) {
          this.phone = this.edit_data.phone
          this.email = this.edit_data.email
          this.address = this.edit_data.address
          this.note = this.edit_data.note
          this.name = this.edit_data.name
          this.karyawan = this.edit_data.karyawan_id
          this.edit_data.branchs.forEach(x => {
            this.branch.push(x.id)
          })

        }
        await this.get_data_master('karyawan','employees')
        await this.get_data_master('branch','branchs')
  }
}
</script>
